<template>
  <div>
    <nav class="level">
      <div class="level-left"></div>
      <div class="level-right">
        <a
          class="modebar-btn"
          data-title="Download plot as a png"
          data-toggle="false"
          data-gravity="n"
          @click="download"
        >
          <svg
            viewBox="0 0 1000 1000"
            class="icon"
            height="1em"
            width="1em"
          >
            <path
              d="m500 450c-83 0-150-67-150-150 0-83 67-150 150-150 83 0 150 67 150 150 0 83-67 150-150 150z m400 150h-120c-16 0-34 13-39 29l-31 93c-6 15-23 28-40 28h-340c-16 0-34-13-39-28l-31-94c-6-15-23-28-40-28h-120c-55 0-100-45-100-100v-450c0-55 45-100 100-100h800c55 0 100 45 100 100v450c0 55-45 100-100 100z m-400-550c-138 0-250 112-250 250 0 138 112 250 250 250 138 0 250-112 250-250 0-138-112-250-250-250z m365 380c-19 0-35 16-35 35 0 19 16 35 35 35 19 0 35-16 35-35 0-19-16-35-35-35z"
              transform="matrix(1 0 0 -1 0 850)"
            ></path>
          </svg>
        </a>
      </div>
    </nav>
    <img width="100%" :src="imgURL"/>
  </div>
</template>

<script>
import url from '@/services/urlEnvironment.js'

export default {
  name: 'PNG',
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {
    imgURL: function () {
      return url.API_URL + '/get_file?file=' + this.data.path
    }
  },
  methods: {
    download: function () {
      let link = document.createElement('a')
      link.setAttribute('href', this.imgURL)
      link.setAttribute('target', '_blank')
      link.setAttribute('download', 'newplot.png')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
</script>

<style scoped>
  .modebar-btn .icon path {
    fill: rgba(68, 68, 68, 0.3);
    text-align: end;
  }
  .modebar-btn .icon path:hover {
    fill: rgba(68, 68, 68, 1.0);
  }
  nav.level {
    margin-bottom: 0px;
  }
</style>
